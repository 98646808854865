import { Button } from '@mui/material'
import React,{useEffect, useState} from 'react'
import { DetailContainer, SwapButton } from "../../styles/Product";
import ProductImage from '../Slider/ProductImage'
import { useCartRequests } from '../../helpers/CartRequests';
import { setBasket } from '../stores/Cart';
import { useDispatch,useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus, faTrash, faTrashAlt, faShoppingCart, faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import axios from 'axios'
import { Helmet } from 'react-helmet';
import Lightbox, { ImagesListType } from 'react-spring-lightbox';


export default function ProductDetail({data}) {

  const [productImages,setProductImages]=useState( data.images.map(x=>{
              return {src:x,loading:'lazy',alt:'deha'}
            }))

  const [currentImageIndex, setCurrentIndex] = useState(0);
  const [openedBox, setOpenedBox] = useState(false);

  const gotoPrevious = () =>
      currentImageIndex > 0 && setCurrentIndex(currentImageIndex - 1);

  const gotoNext = () =>
      currentImageIndex + 1 < productImages.length &&
      setCurrentIndex(currentImageIndex + 1);
      

  const {basket}=useSelector(state=>state.cart)
  const [count,setCount]=useState(1)
  const [currency,setCurrency]=useState(null)
  const dispatch=useDispatch()

  const {addCart,getCart}=useCartRequests()

  const getBasket=()=>{
    getCart().then(res=>{
      dispatch(setBasket(res.data.cart))
    })
  }
  
  const increment=async()=>{
    if(!data.inStock){
      return
    }
    addCart([{id:data.id,count:count}]).then(response=>{
      getBasket()
      toast.success(response.data.message)
      setCount(1)
    })
    .catch(error=>{
      if(error.response!=undefined){
        toast.error(error.response.data.message)
      }
    })

  }
  
  const handleChange=async(e)=>{
    setCount(e.target.value)
  }

  const getCurrency=()=>{
    axios({url:'https://finans.truncgil.com/v3/today.json',headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
      }}).then(res=>{
      setCurrency(res.data)
      console.log("first",res.data)
    })
  }
  
  useEffect(()=>{
    if(currency==null){
      getCurrency()
    }
  },[currency])

  useEffect(()=>{
    setProductImages(data.images.map(x=>{
              return {src:x,loading:'lazy',alt:'deha'}
            }))
  },[data])

  
  return (
    <DetailContainer>
      <Helmet>
        <title>{data.title}</title>
      </Helmet>
      <div className="detail-header">
        <div className="detail-nav">
          <Link to={"/kategori/" + data.groupSlug}>{data.group}</Link>/
          <Link to={"/kategori/" + data.subGroupSlug}>{data.subGroup}</Link>/
          <Link to={"/kategori/" + data.subSubGroupSlug}>
            {data.subSubGroup}
          </Link>
        </div>

        <div className="title">
          <h1 style={{ color: "#000" }}>{data.barcode}</h1>
        </div>
      </div>

      <div className="product">
        <div className="product-image">
          <Lightbox
            isOpen={openedBox}
            onPrev={gotoPrevious}
            onNext={gotoNext}
            images={productImages}
            currentIndex={currentImageIndex}
            onClose={() => setOpenedBox(false)}
            style={{ background: "rgba(0,0,0,.8)" }}
            renderNextButton={() => (
              <SwapButton onClick={gotoNext} className="image-swap-button">
                <FontAwesomeIcon icon={faArrowRight} />
              </SwapButton>
            )}
            renderPrevButton={() => (
              <SwapButton onClick={gotoPrevious} className="image-swap-button">
                <FontAwesomeIcon icon={faArrowLeft} />
              </SwapButton>
            )}
            renderHeader={() => (
              <button
                onClick={() => setOpenedBox(false)}
                style={{
                  display: "none",
                  position: "fixed",
                  fontSize: 22,
                  fontWeight: "bold",
                  color: "#fff",
                  boxShadow: "0px 0px 19px #000",
                  outline: "none",
                  border: "none",
                  zIndex: 999,
                  padding: 10,
                  paddingLeft: 20,
                  paddingRight: 20,
                  right: 0,
                  background: "red",
                }}
              >
                X
              </button>
            )}
            pageTransitionConfig={{
              from: { transform: "scale(0.75)", opacity: 0 },
              enter: { transform: "scale(1)", opacity: 1 },
              leave: { transform: "scale(0.75)", opacity: 0 },
              config: { mass: 1, tension: 320, friction: 32 },
            }}
          />
          <ProductImage
            openedBox={openedBox}
            setOpenedBox={setOpenedBox}
            items={data.images}
          />
        </div>

        <div
          className={
            "product-store " + (data.inStock == false ? "notstock" : "")
          }
        >
          <div className="product-stock">{data.stockText}</div>

          <div className="product-title">{data.title}</div>

          <div className="product-colors">
            {(data.variantProducts!=undefined && data.variantProducts!=null ? data.variantProducts:[]).map((x) => {
              return x.inStock == false ? (
                <React.Fragment key={x.slug}>
                  <Link
                    key={x.slug}
                    className={"no-stock"}
                    to={"/urun/" + x.slug}
                  >
                    {x.color}
                  </Link>
                </React.Fragment>
              ) : (
                <React.Fragment key={x.slug}>
                  <Link
                    className={data.slug == x.slug ? "active" : ""}
                    to={"/urun/" + x.slug}
                  >
                    {x.color}
                  </Link>
                </React.Fragment>
              );
            })}
          </div>

          <div className="price-list">
            <div className="product-price adet">
              ADET {data.price.toFixed(2)} {localStorage.getItem("currency")}
            </div>

            {data.listPrice != data.salePrice ? (
              <div
                className="product-price list-price"
                style={{ marginLeft: 20 }}
              >
                {(data.listPrice * count).toFixed(2)}{" "}
                {localStorage.getItem("currency")}
              </div>
            ) : (
              <></>
            )}

            <div className="product-price" style={{ marginLeft: 20 }}>
              {(data.salePrice * count).toFixed(2)}{" "}
              {localStorage.getItem("currency")}
            </div>
          </div>

          <div>
            <div className="counter">
              <Button
                variant="contain"
                onClick={() => setCount(count * 1 - 1 < 1 ? 1 : count * 1 - 1)}
                className={`buttons ${data.quantity == 1 ? "delete" : ""}`}
              >
                <FontAwesomeIcon
                  icon={data.quantity == 1 ? faTrashAlt : faMinus}
                />{" "}
              </Button>

              <input type="text" onChange={handleChange} value={count} />

              <Button
                variant="contain"
                onClick={() => setCount(count * 1 + 1)}
                className="buttons"
              >
                <FontAwesomeIcon icon={faPlus} />{" "}
              </Button>
            </div>

            <div className="add-basket">
              <Button onClick={increment} variant="contain">
                {" "}
                <span>
                  <FontAwesomeIcon icon={faShoppingCart} />
                </span>{" "}
                {count} {data.usedUnit}{" "}
                {data.usedUnit != "ADET"
                  ? "(" + data.unit2coefficient + ")"
                  : ""}{" "}
                Sepete Ekle
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div className="product-information">
        <h3>Ürün Açıklaması</h3>

        <div
          dangerouslySetInnerHTML={{
            __html: (data.eTradeDescription || "")
              .replace("<[OZELLIK]>", "")
              .replace("<[ACIKLAMA]>", "")
              .replace("<[TARIF]>", "")
              .replace("<[SURELER]>", ""),
          }}
        />

        <p>
          {data.description}. {data.description2}. {data.description3}.{" "}
          {data.description4}
        </p>

        <div className="property"></div>
      </div>
    </DetailContainer>
  );
}
