import React,{useEffect,useState} from 'react'
import {PrintContainer} from '../../styles/Print';
import { useOrderRequests } from '../../helpers/OrderRequests';
export default function Printcollections() {

  const [collections,setCollections]=useState(null)
  const [total,setTotal]=useState(0)
  const {getCollections}=useOrderRequests()
  useEffect(() => {
    if(collections==null){
      getCollections().then(async (response)=>{
        console.log(response.data.data)
        setCollections(response.data.data)
        let totals=0
        let unit=""
        const seter=await response.data.data.collections.map(x=>{
          unit=x.currencyUnit
          totals+=(x.currencyCredit)
        })
        setTotal(unit+totals)

        setTimeout(() => {
          if(response.data.data.collections.length>0){
            window.print();
          }
        }, 2000);
      })
    }
  }, [collections])
  

  return (
    <PrintContainer>
      <h1>Cari Hareket Listesi</h1>
      <ul>
        <li>
          <div>Evrak No</div>
          <div>Tür</div>
          <div>
            Alacak
          </div>
          <div>
            Borç
          </div>
          <div>Tarih</div>
          <div>Açıklama</div>
        </li>
        {(collections!=null && collections.collections != undefined &&
        collections.collections.length > 0
          ? collections.collections
          : []
        ).map((x) => {
          return (
            <li>
              <div>{x.documentNumber}</div>
              <div>{x.collectionType}</div>
              <div>
                {x.currency != true
                  ? x.credit + "₺"
                  : x.currencyCredit + x.currencyUnit}
              </div>
              <div>
                {x.currency != true
                  ? x.debt + "₺"
                  : x.currencyDebt + x.currencyUnit}
              </div>
              <div>{x.date}</div>
              <div>{x.explanation}</div>
            </li>
          );
        })}
        <li>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div>
            Toplam :{" "}
            {collections!=null ? collections.totalCredit : '' + localStorage.getItem("currency")}
          </div>
        </li>
        <li>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div>
            Bakiye :{" "}
            {collections!=null ?  collections.totalBalance :''+ localStorage.getItem("currency")}
          </div>
        </li>
      </ul>
    </PrintContainer>
  );
}
