import React, { useEffect, useState } from "react";
import { PrintContainer } from "../../styles/Print";
import { useOrderRequests } from "../../helpers/OrderRequests";
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
export default function PrintCollection() {
  const {id} = useParams()
  const [collection, setCollection] = useState([]);
  const { getCollection } = useOrderRequests();

  useEffect(() => {

    if (collection.length < 1) {
      getCollection(id).then(async (response) => {
        setCollection(response.data.data);
      

        setTimeout(() => {
          window.print();
        }, 2500);
      });
    }
  }, [collection]);

  return (
    <PrintContainer>
      <div className="makbuz">
        <h1>{collection.collectionType!="FATURA" ? 'Tahsilat Makbuzu':'Fatura Makbuzu'} {collection.systemHasCreated==true ? "( B2B Tahsilatı )":""}</h1>
        <div className="bilgiler">
          <p>
            <strong>Alıcı:</strong> {process.env.REACT_APP_COMPANY_TITLE}
          </p>
          <p>
            <strong>Tarih:</strong> {collection.date}
          </p>
          <p>
            <strong>Tutar:</strong>{" "}
            {collection.currency == false
              ? (collection.collectionType=="FATURA" ? collection.debt:collection.credit) + "₺"
              : (collection.collectionType=="FATURA" ? collection.currencyDebt:collection.currencyCredit )+ collection.currencyUnit}
          </p>
          <p>
            {collection.explanation == null ? (
              <></>
            ) : (
              <>
                <strong>Açıklama:</strong> {collection.explanation}
              </>
            )}
          </p>
        </div>
      </div>
    </PrintContainer>
  );
}
