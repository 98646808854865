import React,{useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { logout } from '../../components/stores/Auth'
import { emptyCart } from '../../components/stores/Cart'
import { useHistory } from 'react-router';

export default function LogoutPage() {
  const dispatch = useDispatch()
  const history=useHistory()

  const goLogOut=async()=>{
    const cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
    localStorage.removeItem("routes");
    localStorage.removeItem("balance");
    localStorage.removeItem("cart");
    localStorage.removeItem('customer')
    localStorage.removeItem('id')
    localStorage.removeItem('currency')
    localStorage.removeItem('user')
    localStorage.removeItem('login')
    localStorage.removeItem('token')
    localStorage.removeItem('admin')
    dispatch(emptyCart())
    dispatch(logout())
    setTimeout(() => {
      window.location=("/giris-yap");
      // history.push("/giris-yap");
    }, 300);
  }

  useEffect(() => {
    goLogOut()
  })
  return (
    <div></div>
  )
}
