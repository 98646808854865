import store from './components/stores/index'


const getMainRoutes=async()=>{

  /*
  JSON:
  */
 let anotherRoutes = [
   { label: "Home", exact: true, path: "/", auth: true },
   {
     label: "Authentication/Logout",
     exact: true,
     path: "/cikis-yap",
     auth: true,
   },
   {
     label: "Authentication/Login",
     exact: true,
     path: "/giris-yap",
     auth: false,
   },
   {
     label: "Authentication/Register",
     exact: true,
     path: "/kayit-ol",
     auth: "disable",
   },
   { label: "Cart", exact: true, path: "/sepetim", auth: true },
   { label: "Discover", exact: true, path: "/kesfet", auth: true },
   { label: "NewProducts", exact: true, path: "/yeni-urunler", auth: true },
   { label: "Product", exact: true, path: "/urun/:slug", auth: true },
   { label: "Category", exact: true, path: "/kategori/:slug", auth: true },
   { label: "Profile", exact: true, path: "/profil/:slug", auth: true },
   { label: "MyProfile", exact: true, path: "/profilim", auth: true },
   { label: "MyProfile", exact: true, path: "/profilim", auth: true },
   { label: "Orders", exact: true, path: "/siparisler", auth: true },
   { label: "Order", exact: true, path: "/siparisler/:id", auth: true },
   { label: "Brand", exact: true, path: "/marka/:slug", auth: true },
   { label: "Search", exact: true, path: "/search/:filter", auth: true },
   { label: "Collections", exact: true, path: "/hareketlerim", auth: true },
   { label: "Collection", exact: true, path: "/hareket/:id", auth: true },
   { label: "Payment", exact: true, path: "/odeme-sayfasi", auth: true },
   { label: "ProductReport", exact: true, path: "/urun-raporu", auth: true },
   {
     label: "ProductReportDetail",
     exact: true,
     path: "/urun-raporu/:id",
     auth: true,
   },
   {
     label: "Print/PrintOrders",
     exact: true,
     path: "/yazdir/siparisler",
     auth: true,
   },
   {
     label: "Print/PrintOrder",
     exact: true,
     path: "/yazdir/siparisler/:id",
     auth: true,
   },
   {
     label: "Print/PrintCollections",
     exact: true,
     path: "/yazdir/hareketler",
     auth: true,
   },
   {
     label: "Payment/SuccessCallback",
     exact: true,
     path: "/odeme-basarili",
     auth: true,
   },
   {
     label: "Payment/FailCallback",
     exact: true,
     path: "/odeme-basarisiz",
     auth: true,
   },
   {
     label: "Payment/CreatePayment",
     exact: true,
     path: "/tahsilat",
     auth: true,
   },
   { label: "Invoices", exact: true, path: "/faturalar", auth: true },
   { label: "Invoice", exact: true, path: "/faturalar/:id", auth: true },
   {
     label: "Print/PrintCollection",
     exact: true,
     path: "/yazdir/hareket/:id",
     auth: true,
   },
   {
     label: "Print/PrintInvoices",
     exact: true,
     path: "/yazdir/faturalar",
     auth: true,
   },
   {
     label: "Print/PrintInvoice",
     exact: true,
     path: "/yazdir/faturalar/:id",
     auth: true,
   },
 ];
  const routes= await store.getState().auth.routes
  
  
  return await ((JSON.stringify(routes).length>10) ? routes:anotherRoutes).map(route=>{
    return {
      label:route.label,
      path:route.path,
      exact:route.exact,
      auth:route.auth,
      props:null,
      component:require('./views/'+route.label+'.js').default
    }
  })
  
}

const Routes=getMainRoutes()

export {Routes}