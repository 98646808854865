
import {createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`

  @import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&family=Overpass:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
  *{
    font-family: 'Comfortaa', sans-serif!important;
    margin-inline-end: 0px;
    padding-inline-end: 0px;
    padding-block-end: 0px;
    margin-block-end: 0px;
    dl,ol,ul{
      margin-bottom:0px!important;
    }
    img{
      user-select:none!important;
    }
  }
  html,body{
    background:${(props) => props.theme.background};
    color:${(props) => props.theme.color};
  }
  
  .changerTheme{
    display:flex;height:100%;justify-content:center,align-items:center;
  }
  
  .authtabList{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0px;
  }
  .authtabList button{
    border:0;
    outline:0;
    background-color: ${({ theme }) => theme.mainThemeBackground};
    padding:10px 40px;
    transition:300ms all;
    color:${({ theme }) => theme.opacityColor};
  }
  .authtabList button[aria-selected="true"]{
    background-color: ${({ theme }) => theme.mainThemeColor};
    color: #fff;
    border:2px solid ${({ theme }) => theme.mainThemeBackground};
  }
  .loader{
    width:100%;
    padding:30px 0px;
    display:flex;
    justify-content:center;
    align-items:center;
    
  }
  .productList-parent{
    position:relative;
    
    width:100%;
    .product-sorter{
      width:100%;
      padding:10px;
      margin-bottom:40px;
      display:flex;top:0px;
      z-index:1;
      justify-content:space-between;
    }
  }
  @media only screen and (max-width:1140px){
    .productList-parent{
      width:100%;
    }
  }
  .unlimited-scroll{
    align-items:center;
    min-height:100vh;
    max-height:100vh;
    overflow:scroll;
    -ms-overflow-style: none;  
    scrollbar-width: none; 
    &::-webkit-scrollbar{
      display: none;
    }
  }
  .content-container{
    display:flex;
    flex-direction:column;
    flex-wrap:wrap;
    justify-content:space-around;
  }
  .invoices{
    padding:7px 20px!important;
    cursor:pointer; 
    font-weight:bold;
    font-family: 'Overpass', sans-serif!important;
    transition:600ms all;
    text-decoration:none;
    display:block;
    color:#000;
    border-radius:10px;
    &:hover{
      border-radius:0px;
      color:#000;
      background:rgba(0,0,0,.1);
      font-weight:bold;
    }
  }
  .cancel{
    margin-left:10px;border:0;outline:0;padding:7px;background:#733;color:#fff;
  }
  .Beklemede{
    background:#fffa65;
  }
  .Onaylandı{
    background:#7bed9f;
  }
  .Muhasebelendi{
    background:#2ed573;
  }
  .İptal{
    background:#e84118;
  }
  .FATURA{
    background:#fbc531;
  }
  .DEKONT{
    background:#9c88ff;
  }
  .BONUS{
    background:#273c75;
  }
  .ÇEK{
    background:#e84118;
  }
  .DEKONT{
    background:#00a8ff;
  }
  .DEVIR{
    background:#ff6b81;
  }
  .EVRAK{
    background:#7bed9f;
  }
  .IRSALIYE{
    background:#2ed573;
  }
  .KREDI-KARTI-IADE{
    background:#ff6348;
  }
  .KREDI-KARTI{
    background:#eccc68;
  }
  .NAKIT{
    background:#ffffff;
  }
  .POS-IADE{
    background:#ffcccc;
  }
  .POS{
    background:#7158e2;
  }
  .SENET{
    background:#67e6dc;
  }
  .SERVIS{
    background:#3ae374;
  }
  .SIPARIS{
    background:#fffa65;
  }
  .TAHAKKUK{
    background:#67e6dc;
  }
  .TAKSIT-VIRMAN{
    background:#67e6dc;
  }
  .VIRMAN{
    background:#67e6dc;
  }

`;
export {GlobalStyle}