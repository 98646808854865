import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React,{useEffect,useRef} from 'react'
import Carousel from 'react-elastic-carousel'
import { useDispatch } from 'react-redux';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
export default function ProductImage({items,openedBox,setOpenedBox}) {
  const carouselRef = useRef(null);
  const dispatch = useDispatch()
  let resetTimeout;
  return (

    <>
      <Carousel 
      ref={carouselRef}
      disableArrowsOnEnd={true}
      pagination={true}
      className="carousel-an-con"
      onChange={()=>{
        //console.log(carouselRef.current.state.activeIndex)
        
      }}
      // itemsToShow={width<1140 ? (width>980 ? 3 : 1) : 5}
      >
      {
        (items==null || items.length==0 ? ["https://propertywiselaunceston.com.au/wp-content/themes/property-wise/images/no-image@2x.png","https://propertywiselaunceston.com.au/wp-content/themes/property-wise/images/no-image@2x.png"] : items).map((item,index)=>{
          return (
            <React.Fragment
              key={"simage-" + Math.floor(Math.random() * 99999)}
              className="carousel-an-con"
            >
              <div
                className="carousel-animate"
                onClick={() => setOpenedBox(!openedBox)}
              >
                <FontAwesomeIcon icon={faSearch} color="#fff" />
              </div>

              <img
                onClick={() => setOpenedBox(!openedBox)}
                className="detail-image"
                src={item}
              />
            </React.Fragment>
          );
        })
      }
    </Carousel>
    </>
  )
}
