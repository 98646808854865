import React,{useEffect,useRef} from 'react'
import Carousel from 'react-elastic-carousel'
import { prominent,average } from 'color.js';
import { useDispatch } from 'react-redux';
import { changeSlider } from '../stores/Site';

export default function Slider({images}) {
  const carouselRef = useRef(null);
  const dispatch = useDispatch()
  let resetTimeout;

  useEffect(() => {
    
  })

  useEffect(() => {
    clearInterval(resetTimeout)
    carouselRef.current.goTo(0)
    setTimeout(() => {
      getColors()
    }, 10);
  }, [images])
  const getColors=()=>{
    if(images.length>0 && carouselRef!=null && carouselRef.current!=null && carouselRef.current.state!=null){
      prominent(images[carouselRef.current.state.activeIndex],{
        format:'rgba',
        amount:4
      }).then((colors)=>{
        updateColors(colors)
      })
      
    }
  }

  const updateColors=colors=>{
    if(typeof(colors[0])=='number'){
      const newColors={
      front:colors.join(','),
      center:colors.join(','),
      centerlast:colors.join(','),
      end:colors.join(',')}
      dispatch(changeSlider(newColors))
    }else{
      let newColors={
      front:(colors[0]!=undefined && colors[0]!=null ? colors[0] : [0,0,0]).join(','),
      center:(colors[1]!=undefined && colors[1]!=null ? colors[1] : [0,0,0]).join(','),
      centerlast:(colors[2]!=undefined && colors[2]!=null ? colors[2] : [0,0,0]).join(','),
      end:(colors[3]!=undefined && colors[3]!=null ? colors[3] : [0,0,0]).join(',')}

      dispatch(changeSlider(newColors))
      // console.log((colors[0]!=undefined && colors[0]!=null ? colors[0] : [0,0,0]).join(','))
      // console.log((colors[1]!=undefined && colors[1]!=null ? colors[1] : [0,0,0]).join(','))
      // console.log((colors[2]!=undefined && colors[2]!=null ? colors[2] : [0,0,0]).join(','))
      // console.log((colors[3]!=undefined && colors[3]!=null ? colors[3] : [0,0,0]).join(','))
    }
  }
  return (
    <Carousel 
      ref={carouselRef}
      enableAutoPlay
      autoPlaySpeed={3000}
      renderPagination={({ pages, activePage, onClick }) => {
        return(
          <div style={{display:'flex'}} className="slider-page-lists">
          {

            pages.map(e=>{
              const isActivePage = activePage === e
              return(
                <div key={Math.floor(Math.random()*99999)}  style={{cursor:'pointer'}} onClick={()=>onClick(e)}>
                  <img onContextMenu={()=>false} className={isActivePage ? 'active-slider-page' : ''} style={{width:50,height:25,margin:10,borderRadius:'100px'}} src={images[e]} />
                </div>
              )
            })
          }
          </div>
        )
      
        
      }}
      onChange={()=>{
        getColors()
      }
      }
      onNextEnd={({ index }) => {
          clearTimeout(resetTimeout)
          if (index  === images.length-1) {
              resetTimeout = setTimeout(() => {
                try {
                  carouselRef.current.goTo(0)
                } catch (error) {
                  
                }
            }, 1400) // same time
          }
      }}
      onPrevStart={({index})=>{
        clearTimeout(resetTimeout)
        if (index  === 0) { 
            resetTimeout = setTimeout(() => {
              carouselRef.current.goTo(images.length-1)
          }, 1400) // same time
        }
      }}
      disableArrowsOnEnd={false}
      itemsToShow={1}>
      {
        images.map((e,index)=>{
          return(
            <img onContextMenu={()=>false} key={Math.floor(Math.random()*99999)} src={e}  alt={"banner-"+index} />
          )
        })
      }
    </Carousel>
  )
}
