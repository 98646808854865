import React,{useState,useRef,useLayoutEffect, useEffect} from 'react'
import Header from '../Navigation/Header'
import BottomNav from '../Navigation/Bottom'
import "react-activity/dist/Sentry.css";
import {ProductList,FilterContainer,ProductListTitle} from '../../styles/Product'
import Title from './Title';
import ProductItem from '../Product/ProductItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faHome, faBorderAll, faList, faUser, faArrowAltCircleDown, faArrowDown, faSort } from '@fortawesome/free-solid-svg-icons';
import { Sentry } from "react-activity";
import CategoryList from './Category/CategoryList';
import { Button } from '@mui/material';
import { Link,useLocation } from 'react-router-dom';
import { Menu } from '@headlessui/react'



export default function Category({search,setSearch,setSelectedBrand,selectedBrand,slug,getNewProducts,getProducts,getSearchedProducts,products,setProducts,simpleCount,sort}) {
  
  const [loading,setLoading] = useState(true)

  const [grid,setGrid] = useState(localStorage.getItem('productList') || 'grid')
  

  const listInnerRef = useRef();
  

  const [page,setPage]=useState(1)

  const [goTopVisibility,setGoTopVisibility] = useState(false)

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if ( scrollHeight-(scrollTop + (clientHeight)) <=70  ) {
    
        getNewProducts(page+1,setLoading,search,setPage,sort!=undefined && sort!=null ? sort.sort : '',sort!=undefined && sort!=null ? sort.sortedBy : '')

      }else{
      
      }
      //if(scrollTop>280){
      //  setGoTopVisibility(true)
      //}else{
      //  setGoTopVisibility(false)
      //}

      
    }
  };

  useLayoutEffect(()=>{
    if(products.length<1){
      setLoading(true)
      getProducts({all:search,sort:sort!=undefined && sort!=null ? sort.sort : '',sortedBy:sort!=undefined && sort!=null ? sort.sortedBy : '',count:10,groupSlug:slug,page:page}).then(res=>{
        if(res.data.data.items && res.data.data.items.length<1){
          setLoading(false)
        }else{
          setProducts(res.data.data.items)
          setLoading(false)
        }
      })
      .catch(err=>{
        if(err.response!=undefined){
          if(err.response.data.data==null){
            setLoading(false)
          }
        }
        console.log(err.response)
      })
    }
  },[products])

  useEffect(()=>{
    setProducts([])
    setPage(1)
  },[slug])


  const handleSearch=(e)=>{
    setSearch(e.target.value)
  }

  useEffect(()=>{
    if(search.length>0){
      setProducts([])
      getSearchedProducts(setLoading,setPage,search,sort!=undefined && sort!=null ? sort.sort : '',sort!=undefined && sort!=null ? sort.sortedBy : '')
    }else{
      setPage(1)
      setProducts([])
      getNewProducts(1,setLoading,search,setPage,sort!=undefined && sort!=null ? sort.sort : '',sort!=undefined && sort!=null ? sort.sortedBy : '')
    }
  },[search,selectedBrand])

  useEffect(()=>{
    if(sort!=undefined && sort!=null){
      setPage(1)
      setProducts([])
      getProducts(1,setLoading,search,setPage)
    }
  },[sort])



  return (
    <div className="unlimited-scroll" ref={listInnerRef}>
      <Header />

      <div className="content-container">
        <FilterContainer>
          {/* <h1>Filtrele</h1> */}
          <div className="filter-container">
            {/* <div className='head'> 
          <input type="text" placeholder='Ara' value={search} onChange={handleSearch} />  <FontAwesomeIcon icon={faSearch} /> 
          </div>  */}
            <CategoryList
              slugPrefix={"/kategori/"}
              slug={slug}
              setSelectedBrand={setSelectedBrand}
              selectedBrand={selectedBrand}
            />
          </div>

          <div className="productList-parent">
            <div className="product-sorter">
              {products.length > 0 && (
                <ProductListTitle>
                  <div className="items">
                    <Menu
                      className={"productSort"}
                      as="nav"
                      style={{ position: "relative" }}
                    >
                      <Menu.Button>
                        {" "}
                        {sort != undefined && sort != null
                          ? sort.name
                          : "Akıllı Sıralama"}{" "}
                        <FontAwesomeIcon icon={faSort} />{" "}
                      </Menu.Button>
                      <Menu.Items className="sortDrop">
                        <Menu.Item>
                          <Link
                            to={{
                              pathname:
                                slug == undefined
                                  ? "/yeni-urunler"
                                  : "/kategori/" + slug,
                              state: {
                                sort: "PRICE",
                                sortedBy: "ASC",
                                name: "Artan Fiyat",
                              },
                            }}
                          >
                            Artan fiyat
                          </Link>
                        </Menu.Item>
                        <Menu.Item>
                          <Link
                            to={{
                              pathname:
                                slug == undefined
                                  ? "/yeni-urunler"
                                  : "/kategori/" + slug,
                              state: {
                                sort: "PRICE",
                                sortedBy: "DESC",
                                name: "Azalan Fiyat",
                              },
                            }}
                          >
                            Azalan fiyat
                          </Link>
                        </Menu.Item>
                        {slug != undefined ? (
                          <>
                            <Menu.Item>
                              <Link
                                to={{
                                  pathname: "/kategori/" + slug,
                                  state: {
                                    sort: "DATE",
                                    sortedBy: "DESC",
                                    name: "Önce en yeni",
                                  },
                                }}
                              >
                                Önce en yeni
                              </Link>
                            </Menu.Item>

                            <Menu.Item>
                              <Link
                                to={{
                                  pathname: "/kategori/" + slug,
                                  state: {
                                    sort: "DATE",
                                    sortedBy: "ASC",
                                    name: "Önce en eski",
                                  },
                                }}
                              >
                                Önce en eski
                              </Link>
                            </Menu.Item>
                          </>
                        ) : (
                          <></>
                        )}
                      </Menu.Items>
                    </Menu>
                  </div>
                </ProductListTitle>
              )}
            </div>
          </div>
        </FilterContainer>

        <div className="productList-parent">
          <ProductList className={` product-${grid}`}>
            {/*  */}

            {products.map((product, index) => {
              return (
                <React.Fragment key={product.id}>
                  <ProductItem product={product} />
                </React.Fragment>
              );
            })}

            <Title
              text={
                <>
                  {loading ? (
                    <>{/* <Sentry color={"#1C8FF7"} size={130} /> */}</>
                  ) : products.length > 0 ? (
                    <div
                      onClick={onScroll}
                      style={{ color: "#000", cursor: "pointer", fontSize: 22 }}
                    ></div>
                  ) : (
                    "Ürün Bulunamadı"
                  )}
                </>
              }
            />
            <div
              onClick={onScroll}
              style={{
                color: "#ffff",
                padding:'5px 55px',
                marginBottom:40,
                borderRadius:100,
                background: "#1C8FF7",
                textAlign: "center",
                cursor: "pointer",
                fontSize: 22,
              }}
            >
              {loading ? <Sentry /> : <>Daha Fazla Ürün Göster</>}
            </div>
          </ProductList>
        </div>
      </div>

      <BottomNav />

      {/* <Footer/> */}
    </div>
  );
}
