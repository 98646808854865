import { faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { faGlobe, faMap, faPhone } from '@fortawesome/free-solid-svg-icons'
import React from 'react'
import { Link } from 'react-router-dom'
import {FooterContainer} from '../../styles/Header'
import logo from '../../mainlogo.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
export default function Footer() {
  return (
    <FooterContainer>
      <div className="about">
        <div>
          <h1>Dehasoft B2B Hizmeti</h1>
          <p> </p>
          <p> </p>
        </div>
        <div>
          <img onContextMenu={() => false} src={logo} />
        </div>
      </div>

      <div className="footerNavs">
        <div>
          <FontAwesomeIcon icon={faMap} />
          Oruçreis mahallesi 6. sokak no 16/a Giyimkent/Esenler
        </div>
        <div>
          <FontAwesomeIcon icon={faPhone} />
          +90 212 535 0474
        </div>
      </div>

      <div className="follow">
        <div>
          <h1></h1>
          {/* <Link to="/"><FontAwesomeIcon icon={faLinkedin}/></Link> */}
          {/* <Link to="/"><FontAwesomeIcon icon={faInstagram}/></Link> */}
          {/* <Link to="/"><FontAwesomeIcon icon={faGlobe}/></Link> */}
        </div>
        <div>
          {/* <h1>Mobil Uygulamalar</h1>
          <Link to="/"><img onContextMenu={()=>false} src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/3c/Download_on_the_App_Store_Badge.svg/2560px-Download_on_the_App_Store_Badge.svg.png"  alt="App-Store-Link"/></Link>
          <Link to="/"><img onContextMenu={()=>false} src="https://www.enersee.io/wp-content/uploads/2021/01/global-playstore.png"  alt="Play-Store-Link"/></Link> */}
        </div>
      </div>

      <div className="copyright">
        <div>&copy; Copyright 2022 - Dehasoft</div>
        <div>
          <a href="https://dehasoft.com.tr/iletisim.html">İletişim</a>
        </div>
        <div>
          <a href="https://dehasoft.com.tr/iletisim.html">
            Mesafeli Satış Sözleşmesi
          </a>
        </div>
        <div>
          <a href="https://dehasoft.com.tr/iletisim.html">
            İptal-İade Politikası
          </a>
        </div>
      </div>
    </FooterContainer>
  );
}
