import React,{useState,useLayoutEffect} from 'react'
import { useSelector } from 'react-redux'
import {SlideProductContainer} from '../styles/Home'
import SlideProducts from '../components/Slider/Products'
import Header from '../components/Navigation/Header'
import BottomNav from '../components/Navigation/Bottom'
import Footer from '../components/Navigation/Footer'
import "react-activity/dist/Sentry.css";
import ContentLoader from "react-content-loader"

import {useProductRequests} from '../helpers/ProductRequests'
import {useOrderRequests} from '../helpers/OrderRequests'
import { DetailContainer } from '../styles/Product'
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faSearch } from '@fortawesome/free-solid-svg-icons'
import { Currency } from 'react-intl-number-format/dist/index.js';

export default function Basket(props) {
  
  const {getDiscover} = useProductRequests()
  const {getOrders} = useOrderRequests()
  
  const {basket}=useSelector(state=>state.cart)

  const [discover,setDiscover]=useState([])
  const [orders,setOrders]=useState([])

  useLayoutEffect(() => {
    
    if(discover.length===0){

      getDiscover({page:1,count:10,sort:"date",sortedBy:Math.floor(Math.random()*10)%2==0 ? "desc":"asc"}).then((response)=>{
        setDiscover(response.data.data.items)
      }).catch((error)=>{
        console.log("error",error.response)
      })

    }
  }, [])

  useLayoutEffect(() => {
    
      if(orders.length===0){

      getOrders().then((response)=>{
        setOrders(response.data.data)
      }).catch((error)=>{
        console.log("error",error.response)
      })

      }
    }, [])
    const columns = [
      {
          name: 'ID',
          selector: row => row.orderId,
          sortable: true,
      },
      {
          name: 'Kod',
          selector: row => row.orderCode,
          sortable: true,
      },
      {
          name: 'Ad',
          selector: row => row.detail,
          sortable: true,

      },
      {
          name: 'Tarih',
          selector: row => row.date,
          sortable: true,

      },
      {
          name: 'Tutar',
          selector: row => <span style={{color:'green',fontWeight:'bold'}}><Currency>{row.totalPrice}</Currency></span>,
          sortable: true,

      },
      {
          name: '',
          selector: row => <Link className={row.status+" invoices"} to={"/siparisler/"+row.orderId}>  {row.status.toUpperCase()}  </Link>,
          sortable: true,
      },
  ];

  const [filterText, setFilterText] = React.useState('');
	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
  const filteredItems = orders.filter(
		item=>{
      console.log((
      (item.date.toLowerCase().includes(filterText.toLowerCase())) || 
      (item.detail.toLowerCase().includes(filterText.toLowerCase())) || 
      ((item.orderCode+"").toLowerCase().includes(filterText.toLowerCase())) || 
      ((item.orderId+"").toLowerCase().includes(filterText.toLowerCase())) || 
      (item.status.toLowerCase().includes(filterText.toLowerCase())) || 
      ((item.totalPrice+"").toLowerCase().includes(filterText.toLowerCase())) 
      ))
      return (
      (item.date.toLowerCase().includes(filterText.toLowerCase())) || 
      (item.detail.toLowerCase().includes(filterText.toLowerCase())) || 
      ((item.orderCode+"").toLowerCase().includes(filterText.toLowerCase())) || 
      ((item.orderId+"").toLowerCase().includes(filterText.toLowerCase())) || 
      (item.status.toLowerCase().includes(filterText.toLowerCase())) || 
      ((item.totalPrice+"").toLowerCase().includes(filterText.toLowerCase())) 
      )
    }
	);

  const subHeaderComponentMemo = React.useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText('');
			}
		};

		return (
			<div style={{display:'flex',alignItems:'center',boxShadow:'0px 0px 6px #000',padding:5,justifyContent:'center'}}>
      <FontAwesomeIcon icon={faSearch} />
      <input style={{border:'none',outline:'none',textAlign:'right'}} placeholder='Ara' className='input-group' value={filterText} onChange={e=>setFilterText(e.target.value)}  />
      </div>
		);
	}, [filterText, resetPaginationToggle]);

  return (
    <>
      
      <Header/>

      <div>
        
        <SlideProductContainer>
          
          {
            orders.length<1 ? 
            
            <>
            <div className="loader">
                <ContentLoader 
                  speed={0.5}
                  width={2500}
                  height={160}
                  viewBox="0 0 2400 160"
                  backgroundColor="#f3f3f3"
                  foregroundColor="#ecebeb"
                  {...props}
                >
                  <rect x="-25" y="0" rx="3" ry="3" width="2429" height="34" /> 
                  <rect x="-28" y="48" rx="3" ry="3" width="2437" height="35" /> 
                  <rect x="-29" y="96" rx="3" ry="3" width="2441" height="35" />
                </ContentLoader>
                
              </div>
              <div className="loader" style={{marginTop:-100}}>
                <ContentLoader 
                  speed={0.5}
                  width={2500}
                  height={160}
                  viewBox="0 0 2400 160"
                  backgroundColor="#f3f3f3"
                  foregroundColor="#ecebeb"
                  {...props}
                >
                  <rect x="-25" y="0" rx="3" ry="3" width="2429" height="34" /> 
                  <rect x="-28" y="48" rx="3" ry="3" width="2437" height="35" /> 
                  <rect x="-29" y="96" rx="3" ry="3" width="2441" height="35" />
                </ContentLoader>
                
              </div>
            </>

            :
            
            <DataTable
              pagination
              subHeader
              subHeaderComponent={subHeaderComponentMemo}
              columns={columns}
              data={(filteredItems)}
            />
          }
          
        </SlideProductContainer>

        <SlideProductContainer>
          
          <h1 style={{color:'#000'}}>Keşfet</h1>

          {
            discover.length > 0 ?
            <SlideProducts items={discover} />
            :
            <div className="loader">
              <ContentLoader speed={0.5} width={221} height={300} style={{marginRight: '20px'}} backgroundColor="#f3f3f3" foregroundColor="#ecebeb" {...props} ><rect x="0" y="0" rx="0" ry="0" width="221" height="250" /><rect x="0" y="260" rx="3" ry="3" width="221" height="10" /><rect x="0" y="275" rx="3" ry="3" width="221" height="10" /><rect x="0" y="290" rx="3" ry="3" width="221" height="10" /></ContentLoader>
              <ContentLoader speed={0.5} width={221} height={300} style={{marginRight: '40px'}} backgroundColor="#f3f3f3" foregroundColor="#ecebeb"  {...props} ><rect x="0" y="0" rx="0" ry="0" width="221" height="250" /><rect x="0" y="260" rx="3" ry="3" width="221" height="10" /><rect x="0" y="275" rx="3" ry="3" width="221" height="10" /><rect x="0" y="290" rx="3" ry="3" width="221" height="10" /> </ContentLoader>
              <ContentLoader speed={0.5} width={221} height={300} style={{marginRight: '40px'}} backgroundColor="#f3f3f3" foregroundColor="#ecebeb"  {...props} ><rect x="0" y="0" rx="0" ry="0" width="221" height="250" /><rect x="0" y="260" rx="3" ry="3" width="221" height="10" /><rect x="0" y="275" rx="3" ry="3" width="221" height="10" /><rect x="0" y="290" rx="3" ry="3" width="221" height="10" /> </ContentLoader>
              <ContentLoader speed={0.5} width={221} height={300} style={{marginRight: '40px'}} backgroundColor="#f3f3f3" foregroundColor="#ecebeb"  {...props} ><rect x="0" y="0" rx="0" ry="0" width="221" height="250" /><rect x="0" y="260" rx="3" ry="3" width="221" height="10" /><rect x="0" y="275" rx="3" ry="3" width="221" height="10" /><rect x="0" y="290" rx="3" ry="3" width="221" height="10" /> </ContentLoader>
              <ContentLoader speed={0.5} width={221} height={300} style={{marginRight: '40px'}} backgroundColor="#f3f3f3" foregroundColor="#ecebeb"  {...props} ><rect x="0" y="0" rx="0" ry="0" width="221" height="250" /><rect x="0" y="260" rx="3" ry="3" width="221" height="10" /><rect x="0" y="275" rx="3" ry="3" width="221" height="10" /><rect x="0" y="290" rx="3" ry="3" width="221" height="10" /> </ContentLoader>
              
            </div>
          }
        </SlideProductContainer> 

      
      </div>

      <BottomNav/>

      <Footer/>

    </>
  )
}
