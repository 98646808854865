import React,{useState} from 'react'
import {  useParams,useLocation } from 'react-router-dom'
import ProductList from '../components/Content/ProductList'
import {useProductRequests} from '../helpers/ProductRequests'
import { Helmet } from 'react-helmet'

export default function Category(props) {
  const [search,setSearch]=useState("")

  const {slug} = useParams()
  const [products,setProducts]=useState([])
  const [totalPage,setTotalPage]=useState(-1)
  const [sort,setSort]=useState(props.location.state!=undefined ? props.location.state.sort : null)
  const [selectedBrand,setSelectedBrand]=useState("")

  const {getProducts}=useProductRequests()
  
  const simpleCount=20
  
  const getNewProducts=async(newpage,setLoading,search,setPage,sort="",sortedBy="")=>{
    if(totalPage!=-1 && totalPage<newpage){
      setLoading(false)
      return
    }
    setLoading(true)
    getProducts({sort:sort,sortedBy:sortedBy,brandSlug:selectedBrand,count:10,groupSlug:slug,page:newpage,all:search})
    .then(async response=>{
      setTotalPage(response.data.totalPage)
      setLoading(false);
      if(response.data.data.items && response.data.data.items.length<1){
      }else{
        setPage(newpage)
        setProducts([...products,...response.data.data.items])
      }
    }).catch(err=>{
      if(err.response!=undefined){
          if(err.response.data.data==null){
            setLoading(false)
          }
        }
    })
  }

  const getSearchedProducts=(setLoading,setPage,search,sort="",sortedBy="")=>{
    setLoading(true)
    getProducts({sort:sort,sortedBy:sortedBy,brandSlug:selectedBrand,count:10,groupSlug:slug,page:1,all:search})
    .then(response=>{
      if(response.data.data.items && response.data.data.items.length<1){
        setLoading(false)
      }else{
        setPage(2)
        setProducts(response.data.data.items)
      }
    }).catch(err=>{
      if(err.response!=undefined){
          if(err.response.data.data==null){
            setLoading(false)
          }
        }
    })
  }

  
  
  return (
    <>
    <Helmet>
      <title>Ürün Listesi</title>
    </Helmet>
    <ProductList
      setSelectedBrand={setSelectedBrand} selectedBrand={selectedBrand}
      slug={slug}
      getNewProducts={getNewProducts}
      getSearchedProducts={getSearchedProducts}
      products={products}
      search={search}
      setSearch={setSearch}
      setProducts={setProducts}
      getProducts={getProducts}
      sort={props.location.state!=undefined ? props.location.state : null}
    />
    </>
  )
}
