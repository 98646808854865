import { faBell, faMoon, faSearch, faShoppingBasket, faSun, faTags, faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import {Header,HeaderTop} from '../../styles/Header'
import Flags from 'country-flag-icons/react/3x2'
import Switch from "react-switch";
import { useSelector,useDispatch } from 'react-redux'
import { changeTheme } from '../stores/Site'
import { useHistory,useLocation } from 'react-router'
import { Menu } from '@headlessui/react'
import Categories from './Categories'

import logo from '../../mainlogo.png'
import { Currency } from 'react-intl-number-format/dist/index.js';
import { useAuthRequests } from '../../helpers/AuthRequests'
import { newCurrencySet, setCustomer } from '../stores/Auth'
import {useCartRequests} from '../../helpers/CartRequests'
import { setBasket } from '../stores/Cart'
export default function HeaderComponent() {

  const dispatch = useDispatch()

  const history=useHistory()
  const location=useLocation()

  const {theme}=useSelector(state=>state.site)

  const {login,currency}=useSelector(state=>state.auth)

  const {admin}=useSelector(state=>state.auth)

  const {basket}=useSelector(state=>state.cart)

  const {setCurrency}=useAuthRequests()
  const {getCart}=useCartRequests()

  const [search,setSearch]=useState("")

  const changeCurrency=async(newCurrency)=>{
    setCurrency(newCurrency).then(async(res)=>{
      localStorage.setItem('balance',res.data.data.balance)
      localStorage.setItem("currency", newCurrency);
      const set=await dispatch(newCurrencySet(newCurrency))
      const cart=await getCart()
      const update=await dispatch(setBasket(cart.data.data))
      setTimeout(() => {
        window.location.reload(false);
      }, 700);
    })
  }
  return (
    <>
      <HeaderTop>
        <ul>
          <li>
            <Switch
              height={20}
              handleDiameter={28}
              width={50}
              onColor={"#beb84a"}
              offHandleColor="#000"
              onHandleColor="#fff"
              checkedHandleIcon={
                <div
                  style={{
                    display: "flex",
                    height: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <FontAwesomeIcon icon={faMoon} />
                </div>
              }
              uncheckedHandleIcon={
                <div
                  style={{
                    display: "flex",
                    height: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <FontAwesomeIcon color={"#fff300"} icon={faSun} />
                </div>
              }
              activeBoxShadow="0px 0px 1px 2px #fffc35"
              checkedIcon={
                <div
                  style={{
                    display: "flex",
                    height: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <FontAwesomeIcon
                    color={"#fff300"}
                    style={{ fontSize: 15 }}
                    icon={faSun}
                  />
                </div>
              }
              uncheckedIcon={
                <div
                  style={{
                    display: "flex",
                    height: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <FontAwesomeIcon style={{ fontSize: 15 }} icon={faMoon} />
                </div>
              }
              checked={theme == "LightTheme" ? true : false}
              onChange={() => dispatch(changeTheme())}
            />
          </li>

          <li>
            <button>
              {currency != null
                ? currency == "TL"
                  ? "TL (Türk Lirası)"
                  : currency == "$"
                  ? "$ ( ABD Doları )"
                  : "€ ( Euro )"
                : ""}
              <div>
                <div onClick={() => changeCurrency("TL")}>₺ (TürkL.)</div>
                <div onClick={() => changeCurrency("$")}>$ (Dolar)</div>
                <div onClick={() => changeCurrency("€")}>€ (Euro)</div>
              </div>
            </button>
          </li>
          {/*
          <li>
            <button><Flags.TR title="Turkey" />
              <div>
                <div><Flags.US title="United States" /></div>
                <div><Flags.AZ title="Azerbaijan" /></div>
                <div><Flags.IT title="Italy" /></div>
                <div><Flags.GR title="Greece" /></div>
                <div><Flags.DE title="Germany" /></div>
              </div>
            </button>
            
          </li> */}
        </ul>
        <ul>
          {/* <li>
            <Link to="/">İndirimli Fiyatlar</Link>
          </li>
          <li>
            <Link to="/">Tüm Kampanyalar</Link>
          </li>
          <li>
            <Link to="/">İade Süreci</Link>
          </li>
          <li>
            <Link to="/">Bize Ulaşın</Link>
          </li>
          <li>
            <Link to="/">Sipariş Takibi</Link>
          </li> */}
        </ul>
      </HeaderTop>
      <Header>
        <div className={"logo " + (location.pathname != "/" ? "box-none" : "")}>
          <Link to="/">
            <img
              onContextMenu={() => false}
              src={"https://dehasoft.com.tr/images/LOGO-p-500.png"}
              alt="Logo-Resim"
            />
          </Link>
        </div>
        <div
          className={
            "search-box " + (location.pathname == "/" ? "box-none" : "")
          }
        >
          <FontAwesomeIcon icon={faSearch} />
          <input
            type="text"
            onKeyUp={(e) => {
              if (e.keyCode == 13) {
                history.push("/search/" + search);
              }
            }}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="ürün, kategori veya marka ara"
          />
          <button onClick={() => history.push("/search/" + search)}>
            {" "}
            ARA{" "}
          </button>
        </div>
        <div
          className={"buttons " + (location.pathname != "/" ? "box-none" : "")}
        >
          {login == false ? (
            <>
              <Menu as="nav" style={{ position: "relative" }}>
                <Menu.Button>
                  {" "}
                  <FontAwesomeIcon icon={faUser} /> Giriş Yap
                </Menu.Button>
                <Menu.Items className="loginDrop">
                  <Menu.Item>
                    <Link to="/giris-yap">Giriş Yap</Link>
                  </Menu.Item>
                  {/* <Menu.Item>
                      <Link to="/kayit-ol" >Kayıt Ol</Link>
                    </Menu.Item> */}
                </Menu.Items>
              </Menu>
            </>
          ) : (
            <>
              <div
                className={
                  "balance " +
                  (1 * localStorage.getItem("balance") > 0 ? "plus" : "times")
                }
              >
                <span>Bakiyeniz</span>
                <Currency>{1 * localStorage.getItem("balance")}</Currency>
                {/* {(JSON.parse(localStorage.getItem('customer'))).currencyUnit} */}
              </div>
              {/* <Link to="/"><FontAwesomeIcon icon={faTags} /></Link> */}
              {/* <Link to="/"><FontAwesomeIcon icon={faBell} /></Link> */}
              <button
                onClick={() => history.push("/sepetim")}
                className="closer"
              >
                <div>
                  <FontAwesomeIcon icon={faShoppingBasket} />
                  <span>
                    {basket != null && basket != undefined ? basket.length : ""}
                  </span>
                </div>
                Sepetim
              </button>

              <Menu as="nav" style={{ position: "relative" }}>
                <Menu.Button>
                  {" "}
                  <FontAwesomeIcon icon={faUser} />{" "}
                  <span style={{ display: "flex", flexWrap: "nowrap" }}>
                    Hesap{" "}
                  </span>{" "}
                </Menu.Button>
                <Menu.Items className="loginDrop">
                  <Menu.Item>
                    <Link to="#" className="balance-mobile">
                      Bakiyeniz <br />
                      {1 * localStorage.getItem("balance")}
                    </Link>
                  </Menu.Item>
                  <Menu.Item>
                    <Link to="/hareketlerim">
                      {JSON.parse(localStorage.getItem("customer")).name}{" "}
                      {JSON.parse(localStorage.getItem("customer")).surname}
                    </Link>
                  </Menu.Item>
                  <Menu.Item>
                    <Link to="/tahsilat">Tahsilat</Link>
                  </Menu.Item>
                  <Menu.Item>
                    <Link to="/hareketlerim">Hareketler</Link>
                  </Menu.Item>
                  <Menu.Item>
                    <Link to="/faturalar">Faturalar</Link>
                  </Menu.Item>
                  <Menu.Item>
                    <Link to="/siparisler">Siparişlerim</Link>
                  </Menu.Item>
                  <Menu.Item>
                    <Link to="/urun-raporu">Ürün Raporu</Link>
                  </Menu.Item>
                  {admin == "true" ? (
                    <Menu.Item>
                      <Link to="/yonetici">Yönetici</Link>
                    </Menu.Item>
                  ) : (
                    <></>
                  )}
                  <Menu.Item>
                    <Link to="/cikis-yap">Çıkış Yap</Link>
                  </Menu.Item>
                </Menu.Items>
              </Menu>
            </>
          )}
        </div>
      </Header>

      <Categories />
    </>
  );
}
