import React,{useState,useLayoutEffect, useEffect} from 'react'
import { useSelector } from 'react-redux'
import {SlideProductContainer} from '../styles/Home'
import SlideProducts from '../components/Slider/Products'
import Header from '../components/Navigation/Header'
import BottomNav from '../components/Navigation/Bottom'
import Footer from '../components/Navigation/Footer'
import "react-activity/dist/Sentry.css";
import ContentLoader from "react-content-loader"

import {useProductRequests} from '../helpers/ProductRequests'
import {useOrderRequests} from '../helpers/OrderRequests'
import { DetailContainer } from '../styles/Product'
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faSearch } from '@fortawesome/free-solid-svg-icons'
import { Currency } from 'react-intl-number-format/dist/index.js';

export default function Basket(props) {
  
  const {getDiscover} = useProductRequests()
  const {getProductReports} = useOrderRequests()
  
  const {basket}=useSelector(state=>state.cart)

  const [discover,setDiscover]=useState([])
  const [reports,setReports]=useState(null)
  
  useLayoutEffect(() => {
    
    if(discover.length===0){

      getDiscover({page:1,count:10,sort:"date",sortedBy:Math.floor(Math.random()*10)%2==0 ? "desc":"asc"}).then((response)=>{
        setDiscover(response.data.data.items)
      }).catch((error)=>{
        console.log("error",error.response)
      })

    }
  }, [])

  useLayoutEffect(() => {
    
    //if(orders.length===0){

    //getOrders().then((response)=>{
    //  setOrders(response.data.data)
    //}).catch((error)=>{
    //  console.log("error",error.response)
    //})

    //}
  }, [])
    const columns = [
      {
          name: 'ID',
          selector: row => row.productId,
          sortable: true,
      },
      {
          name: 'Ad',
          selector: row => row.title,
          sortable: true,
      },
      {
          name: 'Birim',
          selector: row => row.unit,
          sortable: true,
      },
      {
          name: 'Adet',
          selector: row => row.totalQuantity,
          sortable: true,
      },
      {
          name: 'Tutar',
          selector: row => <span style={{color:'green',fontWeight:'bold'}}><Currency>{row.totalAmount}</Currency></span>,
          sortable: true,

      },
  
  ];

  const [filterText, setFilterText] = React.useState('');
  const [startDate, setStartDate] = React.useState(new Date().toISOString().slice(0, 10));
  const [endDate, setEndDate] = React.useState((new Date().toISOString().slice(0, 10)));
  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };
  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };
  
	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);


  const subHeaderComponentMemo = React.useMemo(() => {
  
		return (
			<>
      <div style={{color:'#000'}}>
        <input  type="date"  value={startDate} onChange={handleStartDateChange} style={{border:'none',boxShadow:'0px 0px 2px #333',height:35,textAlign:'center'}}/>
        <span style={{marginRight:10,marginLeft:10,fontWeight:'bold'}}>-</span>
        <input type="date" value={endDate} onChange={handleEndDateChange} style={{border:'none',boxShadow:'0px 0px 2px #333',marginRight:20,height:35,textAlign:'center'}} />
      </div>
      <div style={{display:'flex',alignItems:'center',boxShadow:'0px 0px 1px #333',padding:5,justifyContent:'center'}}>
      <FontAwesomeIcon icon={faSearch} color={"#000"} />
      <input style={{border:'none',outline:'none',textAlign:'left',paddingLeft:5}} placeholder='Ara' className='input-group' value={filterText} onChange={e=>setFilterText(e.target.value)}  />
      </div>
      <div style={{width:'100%',color:'#000'}}>
        <div>
          <span style={{fontSize:25,fontWeight:'bold',fontFamily:'Overpass'}}>Toplam Tutar </span>
          <div style={{backgroundColor:'#1C8FF7',display:'inline-block',marginLeft:20,padding:'3px 12px',borderRadius:5,fontSize:25,color:'#fff',boxShadow:'5px 5px 0px 0px #000',fontFamily:'Overpass'}}><Currency>{reports!=null ? reports.totalAmount : 0}</Currency></div>
        </div>
        <div style={{marginTop:20}}>
          <span style={{fontSize:25,fontWeight:'bold'}}>Toplam Ürün </span>
          <div style={{backgroundColor:'#1C8FF7',display:'inline-block',marginLeft:20,padding:'3px 12px',borderRadius:5,fontSize:25,color:'#fff',boxShadow:'5px 5px 0px 0px #000'}}>{reports!=null ? reports.itemCount:0}</div>
        
        </div>
      </div>
      </>
		);
	}, [filterText, resetPaginationToggle,handleStartDateChange,handleEndDateChange,startDate,endDate]);

  const getReport=()=>{
    getProductReports(startDate,endDate).then(res=>{
      console.log("1",res.data)
      setReports(res.data.data)
    })
  }

  const filteredItems=(items) =>{
    return items.filter(
      item=>{
        return (
        ((item.productId+"").toLowerCase().includes(filterText.toLowerCase())) || 
        (item.title.toLowerCase().includes(filterText.toLowerCase())) || 
        ((item.unit+"").toLowerCase().includes(filterText.toLowerCase())) || 
        ((item.totalQuantity+"").toLowerCase().includes(filterText.toLowerCase())) || 
        ((item.totalAmount+"").toLowerCase().includes(filterText.toLowerCase())) 
        )
      }
    );
  }

  
  useEffect(()=>{
    if(startDate!=null && endDate!=null){
      getReport()
    }
  },[startDate,endDate])
  return (
    <>
      
      <Header/>

      <div>
        
        <SlideProductContainer>
        
     
          {
            false ? 
            
            <>
            <div className="loader">
                <ContentLoader 
                  speed={0.5}
                  width={2500}
                  height={160}
                  viewBox="0 0 2400 160"
                  backgroundColor="#f3f3f3"
                  foregroundColor="#ecebeb"
                  {...props}
                >
                  <rect x="-25" y="0" rx="3" ry="3" width="2429" height="34" /> 
                  <rect x="-28" y="48" rx="3" ry="3" width="2437" height="35" /> 
                  <rect x="-29" y="96" rx="3" ry="3" width="2441" height="35" />
                </ContentLoader>
                
              </div>
              <div className="loader" style={{marginTop:-100}}>
                <ContentLoader 
                  speed={0.5}
                  width={2500}
                  height={160}
                  viewBox="0 0 2400 160"
                  backgroundColor="#f3f3f3"
                  foregroundColor="#ecebeb"
                  {...props}
                >
                  <rect x="-25" y="0" rx="3" ry="3" width="2429" height="34" /> 
                  <rect x="-28" y="48" rx="3" ry="3" width="2437" height="35" /> 
                  <rect x="-29" y="96" rx="3" ry="3" width="2441" height="35" />
                </ContentLoader>
                
              </div>
            </>

            :
            
            <>
            <DataTable
              pagination
              subHeader
              subHeaderComponent={subHeaderComponentMemo}
              columns={columns}
              data={filteredItems(reports!=null ? reports.items : [])}
            />
            </>
          }
          
        </SlideProductContainer>

        <SlideProductContainer>
          
          <h1 style={{color:'#000'}}>Keşfet</h1>

          {
            discover.length > 0 ?
            <SlideProducts items={discover} />
            :
            <div className="loader">
              <ContentLoader speed={0.5} width={221} height={300} style={{marginRight: '20px'}} backgroundColor="#f3f3f3" foregroundColor="#ecebeb" {...props} ><rect x="0" y="0" rx="0" ry="0" width="221" height="250" /><rect x="0" y="260" rx="3" ry="3" width="221" height="10" /><rect x="0" y="275" rx="3" ry="3" width="221" height="10" /><rect x="0" y="290" rx="3" ry="3" width="221" height="10" /></ContentLoader>
              <ContentLoader speed={0.5} width={221} height={300} style={{marginRight: '40px'}} backgroundColor="#f3f3f3" foregroundColor="#ecebeb"  {...props} ><rect x="0" y="0" rx="0" ry="0" width="221" height="250" /><rect x="0" y="260" rx="3" ry="3" width="221" height="10" /><rect x="0" y="275" rx="3" ry="3" width="221" height="10" /><rect x="0" y="290" rx="3" ry="3" width="221" height="10" /> </ContentLoader>
              <ContentLoader speed={0.5} width={221} height={300} style={{marginRight: '40px'}} backgroundColor="#f3f3f3" foregroundColor="#ecebeb"  {...props} ><rect x="0" y="0" rx="0" ry="0" width="221" height="250" /><rect x="0" y="260" rx="3" ry="3" width="221" height="10" /><rect x="0" y="275" rx="3" ry="3" width="221" height="10" /><rect x="0" y="290" rx="3" ry="3" width="221" height="10" /> </ContentLoader>
              <ContentLoader speed={0.5} width={221} height={300} style={{marginRight: '40px'}} backgroundColor="#f3f3f3" foregroundColor="#ecebeb"  {...props} ><rect x="0" y="0" rx="0" ry="0" width="221" height="250" /><rect x="0" y="260" rx="3" ry="3" width="221" height="10" /><rect x="0" y="275" rx="3" ry="3" width="221" height="10" /><rect x="0" y="290" rx="3" ry="3" width="221" height="10" /> </ContentLoader>
              <ContentLoader speed={0.5} width={221} height={300} style={{marginRight: '40px'}} backgroundColor="#f3f3f3" foregroundColor="#ecebeb"  {...props} ><rect x="0" y="0" rx="0" ry="0" width="221" height="250" /><rect x="0" y="260" rx="3" ry="3" width="221" height="10" /><rect x="0" y="275" rx="3" ry="3" width="221" height="10" /><rect x="0" y="290" rx="3" ry="3" width="221" height="10" /> </ContentLoader>
              
            </div>
          }
        </SlideProductContainer> 

      
      </div>

      <BottomNav/>

      <Footer/>

    </>
  )
}
