import React,{useState,useLayoutEffect} from 'react'
import {HeaderAlt} from '../../styles/Header'

import CategoryLoader from '../Content/CategoryLoader';

import { useProductRequests } from '../../helpers/ProductRequests';
import { useHistory } from 'react-router-dom';
const Foo = () => {
  return <div>hello foo</div>;
};
const Bar = () => {
  return <div>hello b</div>;
};
export default function Categories(props) {

  const {getCategories} = useProductRequests();

  const [categories, setCategories] = useState([])

  const history=useHistory()

  useLayoutEffect(() => {
    
    if(categories.length === 0){
      getCategories().then((response)=>{
        setCategories(response.data.data)
      }).catch((error)=>{
        console.log("error",error.response)
      })
    }
    
  }, [])
  const data = [
  {
    label: "foo",
    key: 1,
    items: <Foo />
  },
  {
    label: "bar",
    key: 2,
    items: <Bar />
  },
  {
    label: "Category2",
    key: "Category3",
    items: "Category3 content"
  }
];
  return (
    <HeaderAlt>
      
        <ul>
          {
          categories!=null && categories!=undefined && categories.length > 0 ? 
          <>
            {
              categories.map((category,index)=>{

                return <li  key={"ctg"+category.slug} className={category.name}>
                  <button  onClick={()=>history.push({pathname:'/kategori/'+category.slug})}>{category.name}</button>
                  {/* Alt  kategoriler burada ;  */}
                  {
                    category.subGroups.length > 0 ?
                    <>
                
                          <div className='sub-category' style={{background:category.name=='KADIN'  ? 'pink':'lightblue'}}>
                            {
                              category.subGroups.map((sub_category,index)=>{
                                return <button key={"sb-s"+sub_category.slug} onClick={()=>history.push('/kategori/'+sub_category.slug)}>{sub_category.name.toUpperCase().replace(/\//g,' / ')}</button>
                                  
                              
                              })
                            }
                          </div>
                    </>
                    :
                    // <div>
                    //   <CategoryLoader head={'li'} count={10} />
                    // </div>
                    <></>
                  }
                  </li>
              })
            }
          </>
          :
          <>
            <CategoryLoader head={'li'} count={10} />
          </>
          }
        </ul>
      </HeaderAlt>
  )
}
