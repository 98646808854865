import { faHome, faList, faSearch, faShoppingBag, faShoppingCart, faThList, faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React,{useState} from 'react'
import { Link } from 'react-router-dom'
import {BottomNav} from '../../styles/Header'
import { useHistory } from 'react-router-dom'
import {useSelector} from 'react-redux'
import CategoryPopUp from './PopUp'
export default function Bottom() {

  const history=useHistory()

  const {basket}=useSelector(state=>state.cart)

  const [open,setOpen]=useState(false)

  return (

    <>
    <BottomNav>
        <CategoryPopUp open={open} setOpen={setOpen}/>

        <Link to="/" ><FontAwesomeIcon icon={faHome} />Ana Sayfa</Link>
        <Link to="/yeni-urunler" ><FontAwesomeIcon icon={faSearch} />Ürünler</Link>
        
        {/* <a onClick={()=>setOpen(!open)} ><FontAwesomeIcon icon={faThList} />Kategoriler</a> */}
      
        <Link to="/sepetim" ><FontAwesomeIcon icon={faShoppingCart} />
        <div>
          Sepetim{basket!=null ? <span>{basket.length}</span> : <></>}
        </div>
        </Link>
      
        <Link to="/siparisler" ><FontAwesomeIcon icon={faShoppingBag} />Siparişlerim</Link>
        <Link to="/hareketlerim" ><FontAwesomeIcon icon={faList} />Hareketler</Link>
        {/* <Link to="/urun-raporu" ><FontAwesomeIcon icon={faList} />Hareketler</Link> */}

        {/* <Link to="/" ><FontAwesomeIcon icon={faUser} />Hesabım</Link> */}
    
    </BottomNav>
    </>

  )
}
