import React,{useEffect,useState} from 'react'
import { useProductRequests } from '../../../helpers/ProductRequests';
import { Link } from 'react-router-dom'

import CategoryItems from './CategoryItems';
import BrandItems from './BrandItems';
import { useHistory } from 'react-router';

export default function CategoryList({slug,slugPrefix,setSelectedBrand,selectedBrand}) {
  const {getCategories,getBrands} = useProductRequests();
  const [categories, setCategories] = useState([])
  const [brands, setBrands] = useState([])
  const history=useHistory()

  useEffect(() => {
    if(categories.length === 0){
      getCategories().then((response)=>{
        setCategories(response.data.data)
      }).catch((error)=>{
        console.log("errsor",error)
      })

      getBrands().then((response)=>{
        setBrands(response.data.data)
      }).catch((error)=>{
        console.log("errsor",error)
      })
    }
    
  }, [])

  return ( 
    <div className='filter-item'>

      <ul >
      {
        categories.map(category=>{
          return(
            // <CategoryItems slug={slug} slugPrefix={slugPrefix} category={category} key={category.slug} /> 
              <li key={category.slug} onClick={()=>{history.push((slugPrefix!=undefined ? slugPrefix : '')+category.slug);setSelectedBrand(category.slug)}} className={(category.slug==slug || (category.subGroups.filter(x=>x.slug==slug)).length>0 ? 'active' : '')+" items"}>
                <Link to={(slugPrefix!=undefined ? slugPrefix : '')+category.slug}>{category.name}</Link>
              </li>
            )
          })
        }
        
      </ul>

      <ul>
        {
        categories.map(category=>{
          return(
            
              category.subGroups.map(sub_category=>{
                

                return (
                  <li key={sub_category.slug} onClick={()=>history.push((slugPrefix!=undefined ? slugPrefix : '')+sub_category.slug)} style={{display:category.slug==slug || (category.subGroups.filter(x=>x.slug==slug)).length>0 ? 'flex':'none'}} className={(sub_category.slug==slug ? 'active' : '')+" items"}>
                    <Link to={(slugPrefix!=undefined ? slugPrefix : '')+sub_category.slug}>{sub_category.name.replace(/\//g," ")}</Link>
                  </li>
                )
              })
            
            // <CategoryItems slug={slug} slugPrefix={slugPrefix} category={category} key={category.slug} /> 
             
            )
          })
        }
      </ul>

    </div>
  )
}
